export default function ({app, query}) {
  const utm_parameter = {
    date: '',
    source: query.utm_source ?? '',
    medium: query.utm_medium ?? '',
    campaign: query.utm_campaign ?? '',
    content: query.mtm_content ?? '',
    keyword: query.mtm_keyword ?? '',
    group: query.mtm_group ?? '',
    cid: query.mtm_cid ?? '',
  };

  if (query.mtm_source) {
    utm_parameter.source = query.mtm_source;
  }
  if (query.mtm_medium) {
    utm_parameter.medium = query.mtm_medium;
  }
  if (query.mtm_campaign) {
    utm_parameter.campaign = query.mtm_campaign;
  }

  const isEmpty = !Object.values(utm_parameter).some(
    (x) => x !== null && x !== ''
  );

  if (!isEmpty) {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    utm_parameter.date = today.toISOString();
    app.$cookies.set('utm', utm_parameter, {maxAge: 60 * 24 * 60 * 60});
  }
}
