import merge from '~lodash.mergewith'
import { init, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { CaptureConsole, ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002Fba70b388758544be85cce11f5066be82@o999194.ingest.sentry.io\u002F5977308",
    environment:"prod",
    release:"1.7.7",
    tracesSampleRate:0.01,
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
    new CaptureConsole({ levels:["warn","error"] }),
  ]

  const { browserTracing, vueOptions, ...tracingOptions } = {"tracesSampleRate":0.01,"browserTracing":{"tracePropagationTargets":[]},"vueOptions":{"trackComponents":true}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
