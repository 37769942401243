import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function (context) {
  EventBus.$emit(Events.PageViewed, {
    cookies: {...context.$cookies.getAll()},
    queryParams: {...context.route.query},
    url: context.route.path,
    referrer: typeof document === 'undefined' ? '' : document.referrer,
  });
}
