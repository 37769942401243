import EventBus from '@/events/EventBus';
import Events from '@/events/Events';
import {v4 as uuidv4} from 'uuid';

function loadAndRemoveScript(script_url) {
  if (!script_url) {
    return;
  }

  // Kombinierter Selector für <img> und <script> Elemente
  const combinedSelector = `img[src^="https://click.appcast.io"], script[src^="https://click.appcast.io"]`;

  // Elemente finden und entfernen
  document.querySelectorAll(combinedSelector).forEach((element) => {
    element.parentNode.removeChild(element);
  });

  // Random Hash generieren damit Script nicht gecacht wird
  const randomHash = uuidv4();

  // Skript erstellen und zum DOM hinzufügen
  const script = document.createElement('script');
  script.src = `${script_url}&q=${randomHash}`;
  script.async = true;
  script.defer = false;
  document.head.appendChild(script);
}

export default function () {
  EventBus.$on(Events.JobDetailsViewed, function () {
    loadAndRemoveScript(process.env.NUXT_ENV_APPCAST_JOB_VIEW);
  });
  EventBus.$on(Events.RegistrationAndApplicationOpened, () => {
    loadAndRemoveScript(process.env.NUXT_ENV_APPCAST_JOB_APPLY_START);
  });
  EventBus.$on(Events.RegistrationBeforeApplicationOpened, () => {
    loadAndRemoveScript(process.env.NUXT_ENV_APPCAST_JOB_APPLY_START);
  });
  EventBus.$on(Events.UpdateAndApplicationOpened, () => {
    loadAndRemoveScript(process.env.NUXT_ENV_APPCAST_JOB_APPLY_START);
  });
  EventBus.$on(Events.JobApplicationOpened, () => {
    loadAndRemoveScript(process.env.NUXT_ENV_APPCAST_JOB_APPLY_START);
  });
  EventBus.$on(Events.JobAppliedTo, () => {
    loadAndRemoveScript(process.env.NUXT_ENV_APPCAST_JOB_APPLY_FINISH);
  });
}
