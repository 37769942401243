import {GrowthBook} from '@growthbook/growthbook';

import {v4 as uuidv4} from 'uuid';

// eslint-disable-next-line no-unused-vars
export default async ({app, $cache}, inject) => {
  let visitorId = false;

  // Check and use MatomoVisitorId if exists
  if (app.$cookies.getAll()) {
    let matomoVisitorId = false;

    Object.entries(app.$cookies.getAll()).forEach((entry) => {
      const [key, value] = entry;
      if (key.startsWith('_pk_id')) {
        matomoVisitorId = value.substring(0, value.indexOf('.'));
      }
    });

    if (matomoVisitorId) {
      visitorId = matomoVisitorId;
      app.$cookies.set('pb_visitor_id', matomoVisitorId, {
        maxAge: 60 * 24 * 60 * 60,
      });
    }
  }

  // Set pb_visitor_id-Cookie if not exists
  if (!app.$cookies.get('pb_visitor_id')) {
    visitorId = (uuidv4() + generateRandomBytes(8) + new Date().getTime())
      .replace(/-/g, '')
      .slice(0, 16);

    app.$cookies.set('pb_visitor_id', visitorId, {
      maxAge: 60 * 24 * 60 * 60,
    });
  } else {
    visitorId = app.$cookies.get('pb_visitor_id');
  }

  if (app.$matomo && process.client && visitorId) {
    app.$matomo.setVisitorId(visitorId);
  }

  // Create a GrowthBook instance
  const growthbook = new GrowthBook({
    trackingCallback: (experiment, result) => {
      if (app.$matomo && process.client) {
        // console.log(
        //   'growthbook trackingCallback: $matomo.trackEvent: ',
        //   experiment.key,
        //   '/',
        //   'v' + result.variationId
        // );
        app.$matomo.trackEvent(
          'ExperimentViewed',
          experiment.key,
          'v' + result.variationId,
          1
        );
      }
    },
  });

  const features = await $cache.cache(
    'growthbook_features',
    () => {
      const FEATURES_ENDPOINT =
        'https://cdn.growthbook.io/api/features/key_prod_b4766dff8caae62c';
      return fetch(FEATURES_ENDPOINT)
        .then((res) => res.json())
        .then((json) => json.features)
        .catch(() => {
          // console.log('Failed to fetch feature definitions from GrowthBook');
        });
    },
    parseInt(process.env.NUXT_ENV_LOCALFORAGE_CACHE_TTL ?? 0)
  );

  if (features) {
    growthbook.setFeatures(features);

    if (visitorId) {
      growthbook.setAttributes({
        id: visitorId,
      });
    }
  }

  inject('growthbook', growthbook);

  /*
  if (process.client) {
    app.router.afterEach(() => {
      console.log('growthbook app router afterEach');
      window.growthbook?.refresh();
    });
  }
   */
};

// Funktion, die zufällige Bytes generiert
function generateRandomBytes(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
