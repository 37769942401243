import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.RegistrationBeforeApplicationOpened, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationOpened,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationBeforeApplicationStepStarted, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationStepStarted + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationBeforeApplicationStepFinished, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationStepFinished + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationBeforeApplicationStepBack, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationStepBack + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationBeforeApplicationStepClosed, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationStepClosed + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationBeforeApplicationClosed, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationClosed,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationBeforeApplicationFinished, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationFinished,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationBeforeApplicationSwitchToGoogle, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationSwitchToGoogle + params.type,
      params.jobPostingId,
      5
    );
  });
  EventBus.$on(
    Events.RegistrationBeforeApplicationSwitchToFacebook,
    (params) => {
      $matomo.trackEvent(
        'RegistrationBeforeApplication',
        Events.RegistrationBeforeApplicationSwitchToFacebook + params.type,
        params.jobPostingId,
        5
      );
    }
  );
  EventBus.$on(Events.RegistrationBeforeApplicationSwitchToApple, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationSwitchToApple + params.type,
      params.jobPostingId,
      5
    );
  });
  EventBus.$on(
    Events.RegistrationBeforeApplicationSwitchToMicrosoft,
    (params) => {
      $matomo.trackEvent(
        'RegistrationBeforeApplication',
        Events.RegistrationBeforeApplicationSwitchToMicrosoft + params.type,
        params.jobPostingId,
        5
      );
    }
  );
  EventBus.$on(Events.RegistrationBeforeApplicationForgetPassword, (params) => {
    $matomo.trackEvent(
      'RegistrationBeforeApplication',
      Events.RegistrationBeforeApplicationForgetPassword,
      params.type,
      1
    );
  });
}
