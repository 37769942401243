import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  callWebhook(ctx, {endpoint, params}) {
    getInstance()
      .post(prepareApiUrl(endpoint), params)
      // disregard Webhook errors
      .catch(() => {});
  },
};
