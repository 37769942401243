import {
  getInstance,
  getInstanceWithoutAuthorizationHeader,
} from '@/helper/Axios';
import {prepareUrl, prepareApiUrl} from '@/helper/UrlHelper';
import {getXhrCache} from '@/helper/XhrCache';

export const actions = {
  async getJobPostingsFromTypesenseMultisearch(
    ctx,
    {collection_name, getParameters = new URLSearchParams(), searches}
  ) {
    const headers = {};
    if (process.env.NUXT_ENV_TYPESENSE_API_KEY)
      headers['X-TYPESENSE-API-KEY'] = process.env.NUXT_ENV_TYPESENSE_API_KEY;
    const response = await getInstanceWithoutAuthorizationHeader().post(
      prepareUrl(process.env.NUXT_ENV_TYPESENSE_MULTISEARCH, {
        routeParameters: {collection_name},
        getParameters,
      }),
      {searches},
      {headers}
    );
    return response.data;
  },

  async getJobPostings(ctx, getParameters = new URLSearchParams()) {
    getParameters.set('isPublished', '1');
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );
    return {
      data: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    };
  },

  async getJobPostingsTotalNumber() {
    return await getXhrCache()
      .getResourceById('job-posting-number')
      .then((resource) => resource.total);
  },

  async getLandingpageJobPostingsTotalNumber(ctx, key) {
    return await getXhrCache()
      .getResourceById(key)
      .then((resource) => resource.total);
  },

  async getJobPostingsActiveKeys() {
    return await getXhrCache()
      .getResourceById('job-posting-active-keys')
      .then((resource) => resource.total);
  },

  async getJobPosting(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTING_ENDPOINT, {
        routeParameters: {id},
      })
    );
    return response.data;
  },

  async getJobPostingBySlug(ctx, slug) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTING_BY_SLUG_ENDPOINT, {
        routeParameters: {slug: encodeURIComponent(slug)},
      })
    );
    return response.data;
  },

  async getTopJobPosting(ctx, filterParams = new URLSearchParams()) {
    const getParameters = new URLSearchParams(filterParams);
    getParameters.append('itemsPerPage', '1');
    getParameters.append('order[random]', 'asc');
    getParameters.append('top_job', '1');
    getParameters.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParameters.set('notYetAppliedTo', this.$auth.user.id);
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );
    return response.data['hydra:member'][0] ?? null;
  },

  async getInstantTeaserJobPostings(ctx, filterParams = new URLSearchParams()) {
    const getParameters = new URLSearchParams(filterParams);
    getParameters.append('itemsPerPage', '3');
    getParameters.append('order[random]', 'asc');
    getParameters.append('instant_teaser_job', '1');
    getParameters.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParameters.set('notYetAppliedTo', this.$auth.user.id);
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );
    return response.data['hydra:member'];
  },

  async getJobPostingSpecialOffers() {
    // case rein: wenn env-variable leer keinen request sondern leeres array zurückgeben
    if (!process.env.NUXT_ENV_SPECIAL_OFFER_JOB_IDS) {
      return [];
    }

    const getParameters = new URLSearchParams();
    if (this.$auth.loggedIn)
      getParameters.set('notYetAppliedTo', this.$auth.user.id);
    process.env.NUXT_ENV_SPECIAL_OFFER_JOB_IDS.split(',').forEach((id) => {
      getParameters.append('id[]', id);
    });
    getParameters.set('isPublished', '1');

    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );

    return response.data['hydra:member'];
  },

  async getJobPostingTopOffers(ctx, filterParams = new URLSearchParams()) {
    const getParametersTopJobs = new URLSearchParams(filterParams);
    getParametersTopJobs.append('top_job', '1');
    getParametersTopJobs.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParametersTopJobs.set('notYetAppliedTo', this.$auth.user.id);

    const topJobsResponsePromise = getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters: getParametersTopJobs,
      })
    );

    const getParametersInstantTeaserJobs = new URLSearchParams(filterParams);
    getParametersInstantTeaserJobs.append('instant_teaser_job', '1');
    getParametersInstantTeaserJobs.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParametersInstantTeaserJobs.set('notYetAppliedTo', this.$auth.user.id);

    const instantTeaserJobsResponsePromise = getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters: getParametersInstantTeaserJobs,
      })
    );

    let getParameters = new URLSearchParams(filterParams);
    getParameters.append('itemsPerPage', '20');
    getParameters.append('top_offer', '1');
    getParameters.append('sponsored', '1');
    getParameters.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParameters.set('notYetAppliedTo', this.$auth.user.id);

    if (
      this.$auth.loggedIn &&
      this.$auth.user.candidateProfile.addresses &&
      this.$auth.user.candidateProfile.addresses.length > 0 &&
      this.$auth.user.candidateProfile.addresses[0].geoCluster &&
      !filterParams.get('distance')
    )
      getParameters.set(
        'geoCluster_typesense',
        this.$auth.user.candidateProfile.addresses[0].geoCluster
      );

    const topOffersSponsoredResponsePromise = getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );

    getParameters = new URLSearchParams(getParameters);
    getParameters.set('sponsored', '0');
    getParameters.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParameters.set('notYetAppliedTo', this.$auth.user.id);

    if (
      this.$auth.loggedIn &&
      this.$auth.user.candidateProfile.addresses &&
      this.$auth.user.candidateProfile.addresses.length > 0 &&
      this.$auth.user.candidateProfile.addresses[0].geoCluster &&
      !filterParams.get('distance')
    )
      getParameters.set(
        'geoCluster_typesense',
        this.$auth.user.candidateProfile.addresses[0].geoCluster
      );

    const topOffersNotSponsoredResponsePromise = getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );

    const [
      topJobsResponseResponse,
      instantTeaserJobsResponseResponse,
      topOffersSponsoredResponse,
      topOffersNotSponsoredResponse,
    ] = await Promise.all([
      topJobsResponsePromise,
      instantTeaserJobsResponsePromise,
      topOffersSponsoredResponsePromise,
      topOffersNotSponsoredResponsePromise,
    ]);

    const data = [
      ...topJobsResponseResponse.data['hydra:member'],
      ...instantTeaserJobsResponseResponse.data['hydra:member'],
      ...topOffersSponsoredResponse.data['hydra:member'],
      ...topOffersNotSponsoredResponse.data['hydra:member'],
    ];

    return {
      data: data,
      totalItems: data.length,
    };
  },

  async getJobPostingSimilarOffers(ctx, {id, numberJobs}) {
    const similarOffersResponse = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTING_RECOMMEND_ENDPOINT, {
        routeParameters: {id, numberJobs},
      })
    );

    const data = [...similarOffersResponse.data['hydra:member']];

    return {
      data: data,
      totalItems: data.length,
    };
  },

  async getJobPostingOpportunities(ctx, filterParams = new URLSearchParams()) {
    let getParameters = new URLSearchParams(filterParams);

    getParameters.append('itemsPerPage', '20');
    getParameters.append('opportunity', '1');
    getParameters.append('sponsored', '1');
    getParameters.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParameters.set('notYetAppliedTo', this.$auth.user.id);

    if (
      this.$auth.loggedIn &&
      this.$auth.user.candidateProfile.addresses &&
      this.$auth.user.candidateProfile.addresses.length > 0 &&
      this.$auth.user.candidateProfile.addresses[0].geoCluster &&
      !filterParams.get('distance')
    )
      getParameters.set(
        'geoCluster_typesense',
        this.$auth.user.candidateProfile.addresses[0].geoCluster
      );

    const opportunitiesSponsoredResponsePromise = getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );

    getParameters = new URLSearchParams(getParameters);
    getParameters.set('sponsored', '0');
    getParameters.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParameters.set('notYetAppliedTo', this.$auth.user.id);

    if (
      this.$auth.loggedIn &&
      this.$auth.user.candidateProfile.addresses &&
      this.$auth.user.candidateProfile.addresses.length > 0 &&
      this.$auth.user.candidateProfile.addresses[0].geoCluster &&
      !filterParams.get('distance')
    )
      getParameters.set(
        'geoCluster_typesense',
        this.$auth.user.candidateProfile.addresses[0].geoCluster
      );

    const opportunitiesNotSponsoredResponsePromise = getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );

    const [opportunitiesSponsoredResponse, opportunitiesNotSponsoredResponse] =
      await Promise.all([
        opportunitiesSponsoredResponsePromise,
        opportunitiesNotSponsoredResponsePromise,
      ]);

    const data = [
      ...opportunitiesSponsoredResponse.data['hydra:member'],
      ...opportunitiesNotSponsoredResponse.data['hydra:member'],
    ];

    return {
      data: data,
      totalItems: data.length,
    };
  },

  async getJobPostingRecommendations(
    ctx,
    filterParams = new URLSearchParams()
  ) {
    const getParameters = new URLSearchParams(filterParams);

    getParameters.append('itemsPerPage', '20');
    getParameters.append('recommend', '30;' + this.$auth.user.id);
    getParameters.set('isPublished', '1');

    const recommendations = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );

    const data = recommendations.data['hydra:member'];

    return {
      data: data,
      totalItems: data.length,
    };
  },

  async getJobPostingNewJobs(ctx, filterParams = new URLSearchParams()) {
    const getParameters = new URLSearchParams(filterParams);

    getParameters.append('itemsPerPage', '20');
    getParameters.append('interval_created', '1 day');
    //getParameters.append('interval_changed', '5 days');
    getParameters.set('isPublished', '1');
    if (this.$auth.loggedIn)
      getParameters.set('notYetAppliedTo', this.$auth.user.id);

    if (
      this.$auth.loggedIn &&
      this.$auth.user.candidateProfile.addresses &&
      this.$auth.user.candidateProfile.addresses.length > 0 &&
      this.$auth.user.candidateProfile.addresses[0].geoCluster &&
      !filterParams.get('distance')
    )
      getParameters.set(
        'geoCluster_typesense',
        this.$auth.user.candidateProfile.addresses[0].geoCluster
      );

    const newJobs = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTINGS_ENDPOINT, {
        getParameters,
      })
    );

    const data = newJobs.data['hydra:member'];

    return {
      data: data,
      totalItems: data.length,
    };
  },
};
