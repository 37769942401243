
  import ScreenSize from '@/mixins/ScreenSize';
  import Events from '@/events/Events';
  import clearableTabIndex from '@/mixins/clearableTabIndex';

  export default {
    name: 'LoginAndRegistrationDialog',
    components: {
      RegistrationForm: () =>
        import(
          '@/components/organization/LoginAndRegistrationDialog/RegistrationForm'
        ),
      RegistrationSuccessPage: () =>
        import(
          '@/components/organization/LoginAndRegistrationDialog/RegistrationSuccessPage'
        ),
    },
    mixins: [clearableTabIndex, ScreenSize],
    props: {
      buttonClass: {
        type: String,
        required: false,
        default: '',
      },
      textButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      blockButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      showActivator: {
        type: Boolean,
        required: false,
        default: false,
      },
      openDirectDialog: {
        type: String,
        required: false,
        default: '',
      },
      job: {
        type: Object,
        required: false,
        default: () => {},
      },
      comeFrom: {
        type: String,
        required: false,
        default: 'detailPage',
      },
      position: {
        type: String,
        required: false,
        default: 'NULL',
      },
    },
    data() {
      return {
        dialog: false,
        dialogWidth: 900,
        page: null,
      };
    },
    watch: {
      dialog(val) {
        if (!val && this.$route.query.success) {
          const updatedQuery = {...this.$route.query};
          delete updatedQuery.success;
          this.$router.push({path: this.$route.path, query: updatedQuery});
        }
      },
    },
    created() {
      if (this.openDirectDialog && this.openDirectDialog != '') {
        this.openDialog(this.openDirectDialog);
      }
    },
    methods: {
      closeDialog() {
        this.dialog = false;
        const currentHash = this.$route.hash;
        this.$router.push({
          path: this.$route.path + currentHash ? currentHash : '',
        });
        this.$emitWithClientData(Events.OrganizationRegistrationStepClosed);
      },
      changePage(page) {
        this.page = page;
        this.dialogWidth = 900;

        if (page === 'registration') {
          this.$emitWithClientData(Events.OrganizationRegistrationOpened);
          this.$emitWithClientData(Events.OrganizationRegistrationStepStarted);
        }
      },
      openRegistration(on) {
        return ($event) => {
          on.click($event);
          this.dialogWidth = 900;
          this.page = 'registration';
          this.$emitWithClientData(Events.OrganizationRegistrationOpened);
          this.$emitWithClientData(Events.OrganizationRegistrationStepStarted);
        };
      },
      openRegistrationSuccess(on) {
        return ($event) => {
          on.click($event);
          this.dialogWidth = 900;
          this.page = 'registrationSuccess';
        };
      },
      openDialog(page) {
        this.dialog = true;
        this.dialogWidth = 900;
        this.page = page;
      },
    },
  };
