import {
  calculateProfileCompleteness as calculateProfileCompletenessHelper,
  calculatePersonalDataCompleteness,
  calculateAddressesCompleteness,
  calculateJobEmploymentCompleteness,
  calculateCommunicationDataCompleteness,
  calculateSocialProfilesCompleteness,
  calculateAppearanceCompleteness,
  calculateLanguagesCompleteness,
  calculateWorkPreferenceCompleteness,
  calculateMobilityCompleteness,
  calculateSchoolingCompleteness,
  calculatePromotionExperiencesCompleteness,
  calculateWorkExperiencesCompleteness,
  calculateBankDataCompleteness,
  calculateTaxCompleteness,
  calculateImageCompleteness,
  calculateVideoCompleteness,
  calculateDocumentCompleteness,
  hasRelevantDataForApplication,
  calculateActivitySkillsCompleteness,
} from '@/helper/ProfileCompletenessHelper';

export const state = () => ({
  maxValue: 0,
  value: 0,
  percent: 0,
  hasRelevantDataForApplication: false,
  groups: {
    'personal-data': false,
    addresses: false,
    'communication-data': false,
    'social-profiles': false,
    appearance: false,
    languages: false,
    mobility: false,
    tax: false,
    'bank-data': false,
    'promotion-experiences': false,
    'activity-skills': false,
    'work-experiences': false,
    'work-preference': false,
    images: false,
    videos: false,
    documents: false,
  },
});

export const mutations = {
  setCompleteness(state, {maxValue, value, percent}) {
    state.maxValue = maxValue;
    state.value = value;
    state.percent = percent;
  },
  setHasRelevantDataForApplication(state, value) {
    state.hasRelevantDataForApplication = value;
  },
  setGroupCompleteness(state, {groupName, complete}) {
    state.groups[groupName] = complete;
  },
};

export const actions = {
  calculateProfileCompleteness({commit, rootState}) {
    const {maxValue, value, percent} = calculateProfileCompletenessHelper(
      rootState.auth.user.candidateProfile
    );
    commit('setCompleteness', {maxValue, value, percent});

    commit(
      'setHasRelevantDataForApplication',
      hasRelevantDataForApplication(rootState.auth.user.candidateProfile)
    );

    commit('setGroupCompleteness', {
      groupName: 'personal-data',
      complete: calculatePersonalDataCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'addresses',
      complete: calculateAddressesCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'job-employment',
      complete: calculateJobEmploymentCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'communication-data',
      complete: calculateCommunicationDataCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'social-profiles',
      complete: calculateSocialProfilesCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'appearance',
      complete: calculateAppearanceCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'languages',
      complete: calculateLanguagesCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'activity-skills',
      complete: calculateActivitySkillsCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'work-preference',
      complete: calculateWorkPreferenceCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'mobility',
      complete: calculateMobilityCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'schooling',
      complete: calculateSchoolingCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'work-experiences',
      complete: calculateWorkExperiencesCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'promotion-experiences',
      complete: calculatePromotionExperiencesCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'tax',
      complete: calculateTaxCompleteness(rootState.auth.user.candidateProfile),
    });

    commit('setGroupCompleteness', {
      groupName: 'bank-data',
      complete: calculateBankDataCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'images',
      complete: calculateImageCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'videos',
      complete: calculateVideoCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });

    commit('setGroupCompleteness', {
      groupName: 'documents',
      complete: calculateDocumentCompleteness(
        rootState.auth.user.candidateProfile
      ),
    });
  },
};
