import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.RegistrationOrLoginOpened, () => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginOpened,
      '',
      1
    );
  });
  EventBus.$on(Events.RegistrationOrLoginFinished, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginFinished,
      params.type,
      1
    );
  });
  EventBus.$on(Events.RegistrationOrLoginStepStarted, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginStepStarted + params.step,
      '',
      1
    );
  });
  EventBus.$on(Events.RegistrationOrLoginStepFinished, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginStepFinished + params.step,
      '',
      1
    );
  });
  EventBus.$on(Events.RegistrationOrLoginStepClosed, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginStepClosed + params.step,
      '',
      1
    );
  });
  EventBus.$on(Events.RegistrationOrLoginSwitchToGoogle, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginSwitchToGoogle + params.type,
      '',
      5
    );
  });
  EventBus.$on(Events.RegistrationOrLoginSwitchToFacebook, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginSwitchToFacebook + params.type,
      '',
      5
    );
  });
  EventBus.$on(Events.RegistrationOrLoginSwitchToApple, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginSwitchToApple + params.type,
      '',
      5
    );
  });
  EventBus.$on(Events.RegistrationOrLoginSwitchToMicrosoft, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginSwitchToMicrosoft + params.type,
      '',
      5
    );
  });
  EventBus.$on(Events.RegistrationOrLoginForgetPassword, (params) => {
    $matomo.trackEvent(
      'RegistrationOrLogin',
      Events.RegistrationOrLoginForgetPassword,
      params.type,
      1
    );
  });
}
