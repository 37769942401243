import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function (store) {
  EventBus.$on(Events.PageViewed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_PAGE_VIEWED,
      params,
    });
  });
}
