export const state = () => ({
  notificationQueue: [],
});

export const mutations = {
  showNotification(state, payload) {
    state.notificationQueue.push({
      message: payload.message,
      color: payload.color,
      timeout: payload.timeout,
      url: payload.url,
      urlTitle: payload.urlTitle,
      applicationUrl: payload.applicationUrl,
      width: payload.width,
      reloadButton: payload.reloadButton,
    });
  },
  removeOldestNotification(state) {
    state.notificationQueue.shift();
  },
};

export const actions = {
  getOldestNotification({state, commit}) {
    const [first] = state.notificationQueue;
    commit('removeOldestNotification');
    return first;
  },
};

export const getters = {
  hasNotificationsPending: (state) => state.notificationQueue.length > 0,
};
