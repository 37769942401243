import EventBus from '@/events/EventBus';

export default {
  methods: {
    async $emitWithClientData(
      event,
      params = {},
      queryParams = undefined,
      url = undefined
    ) {
      const cookies = {...this.$cookies.getAll()};
      const referrer = typeof document === 'undefined' ? '' : document.referrer;
      queryParams = queryParams ?? {...this.$route.query};
      url = url ?? this.$route.path;
      const blockList = [
        '34.243.163.16',
        '34.243.16.56',
        '54.229.230.128',
        '52.18.57.51',
        '54.73.67.246',
        '18.203.110.190',
        '3.253.4.71',
        '18.202.17.145',
        '34.255.120.124',
        '52.211.224.108',
        '34.247.73.29',
        '3.252.122.189',
        '63.32.54.42',
      ];
      if (this.$device.isCrawler) {
        return;
      }
      const device = {userAgent: this.$device.userAgent, ssr: process.server};
      const ip = this.$store.state.userIp.ip;

      if (
        blockList.includes(ip) ||
        (!this.$auth.loggedIn &&
          Object.keys(cookies).length === 0 &&
          queryParams.utm_source === 'multiposting' &&
          referrer === '') ||
        device.userAgent.includes('bot') ||
        device.userAgent.includes('spider') ||
        device.userAgent.includes('crawler')
      ) {
        return;
      }

      EventBus.$emit(event, {
        cookies: cookies,
        queryParams: queryParams,
        url: url,
        referrer: referrer,
        device: device,
        ip: ip,

        ...params,
      });
    },
  },
};
