export default async function ({app, $auth, $axios, route}) {
  // set _switch_user header and refetch user but remove again if that fails
  if ($auth.loggedIn && route.path === '/' && route.query._switch_user) {
    $axios.defaults.headers.common['_switch_user'] = route.query._switch_user;
    await $auth.fetchUser().catch(() => {
      delete $axios.defaults.headers.common['_switch_user'];
    });
  }

  // remove _switch_user get param from url
  app.router.beforeEach((to, from, next) => {
    if (to.query._switch_user) {
      delete to.query._switch_user;
      next(to);
    } else {
      next();
    }
  });
}
