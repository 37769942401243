import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  async createJobAlert(ctx, data) {
    const response = await getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_ALERT_CREATE_ENDPOINT),
      data
    );
    return response.data;
  },
  async getJobAlerts(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_ALERT_GET_ENDPOINT, {
        routeParameters: id,
      })
    );
    if (response.status !== 200) {
      throw 'Error while geting job alerts from user';
    }
    return {
      data: response.data['hydra:member'],
    };
  },
  async deleteJobAlert(ctx, id) {
    const response = await getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_ALERT_DELETE_ENDPOINT, {
        routeParameters: id,
      }),
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
    return {
      data: response.data,
    };
  },
  async confirmJobAlert(ctx, id) {
    const response = await getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_ALERT_CONFIRM_ENDPOINT, {
        routeParameters: id,
      }),
      {},
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
    return response.data;
  },
  async pauseJobAlert(ctx, id) {
    const response = await getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_ALERT_PAUSE_ENDPOINT, {
        routeParameters: id,
      }),
      {},
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
    return response.data;
  },
  async unpauseJobAlert(ctx, id) {
    const response = await getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_ALERT_UNPAUSE_ENDPOINT, {
        routeParameters: id,
      }),
      {},
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
    return response.data;
  },
  async updateJobAlert(ctx, {id, data}) {
    const response = await getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_JOB_ALERT_UPDATE_ENDPOINT, {
        routeParameters: {id},
      }),
      data,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
    return response.data;
  },
};
