class NoCache {
  has() {
    return false;
  }

  get() {
    return null;
  }

  set() {}

  cache(key, func) {
    return func();
  }
}

export default NoCache;
