
  import imageToBase64 from '@/helper/imageToBase64';

  const cache = {};

  export default {
    name: 'BaseSecureImg',
    props: {
      url: {
        type: String,
        required: true,
      },
      alwaysShowPlaceholder: {
        type: Boolean,
        default: false,
      },
      cache: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      image: null,
    }),
    watch: {
      url() {
        this.loadImage();
      },
      alwaysShowPlaceholder() {
        this.loadImage();
      },
    },
    mounted() {
      this.loadImage();
    },
    methods: {
      loadImage() {
        if (this.alwaysShowPlaceholder) {
          this.image = null;
          return;
        }

        let request;
        if (!this.cache) {
          request = this.makeRequest();
        } else {
          if (!cache[this.url]) {
            cache[this.url] = this.makeRequest();
          }
          request = cache[this.url];
        }
        request.then((base64) => {
          this.image = base64;
        });
      },
      makeRequest() {
        return imageToBase64(this.url);
      },
    },
  };
