export default function (context) {
  let ip;

  // When running on server
  if (process.server) {
    ip =
      context.req.headers['x-real-ip'] ||
      context.req.headers['x-forwarded-for'] ||
      context.req.connection.remoteAddress;

    // If IP address is IPv6 format, convert it to IPv4
    if (ip && ip.substr(0, 7) == '::ffff:') {
      ip = ip.substr(7);
    }

    context.store.commit('userIp/setIp', ip);
  }
}
