
  export default {
    name: 'DefaultDrawerRight',
    data() {
      return {
        switchToTitle: 'Zu Jobsuchenden wechseln',
        colors: [this.$vuetify.theme.themes.light.primary],
        menu: false,
      };
    },
  };
