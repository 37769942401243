import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

function createFormData(file, filename) {
  const formData = new FormData();
  formData.append('file', file, filename + '_' + file.name);
  return formData;
}

export const actions = {
  deleteProfileVideo(ctx, id) {
    return getInstance().delete(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_VIDEOS_DELETE_ENDPOINT, {
        routeParameters: {id},
      })
    );
  },
  postProfileVideoGoals(ctx, {id, file}) {
    return getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_VIDEOS_GOALS_ENDPOINT, {
        routeParameters: {id},
      }),
      createFormData(file, 'goals')
    );
  },
  postProfileVideoIntroduction(ctx, {id, file}) {
    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_PROFILE_VIDEOS_INTRODUCTION_ENDPOINT,
        {
          routeParameters: {id},
        }
      ),
      createFormData(file, 'intruduction')
    );
  },
  postProfileVideoSellingPoint(ctx, {id, file}) {
    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_PROFILE_VIDEOS_SELLING_POINT_ENDPOINT,
        {
          routeParameters: {id},
        }
      ),
      createFormData(file, 'selling_point')
    );
  },
};
