export const state = () => ({
  paramsFromLastClickOnDetails: null,
  paramsFromLastClickOnApplication: null,
});

export const mutations = {
  setParamsFromLastClickOnDetails(state, params) {
    params = {...params};
    delete params.cookies;
    delete params.queryParams;
    delete params.url;
    delete params.referrer;
    state.paramsFromLastClickOnDetails = params;
  },
  setParamsFromLastClickOnApplication(state, params) {
    params = {...params};
    delete params.cookies;
    delete params.queryParams;
    delete params.url;
    delete params.referrer;
    state.paramsFromLastClickOnApplication = params;
  },
};
