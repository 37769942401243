import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  async getInvoices(ctx, getParameters = {}) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_LEGACY_BILLING_ENDPOINT, {
        getParameters,
      })
    );
    if (response.status !== 200) {
      throw 'Error while loading Invoices';
    }
    return {
      data: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    };
  },
  async getInvoicesPrint(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_LEGACY_BILLING_PRINT_ENDPOINT, {
        routeParameters: {id},
      }),
      {responseType: 'blob'}
    );
    if (response.status !== 200) {
      throw 'Error while loading Invoices';
    }
    return {
      data: response,
    };
  },
};
