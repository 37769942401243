import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function (store) {
  EventBus.$on(Events.ByllAccountCreated, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_BYLL_ACCOUNT_CREATED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ByllUsed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_BYLL_USED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.EmailVerified, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_EMAIL_VERIFIED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.JobAppliedTo, (params) => {
    const stateParams =
      params.comeFrom === 'detailPage'
        ? store.state.tracking.paramsFromLastClickOnDetails ?? {}
        : store.state.tracking.paramsFromLastClickOnApplication ?? {};
    delete params.comeFrom;
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_APPLIED_TO_ENDPOINT,
      params: {...stateParams, ...params},
    });
  });
  EventBus.$on(Events.JobFavored, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_FAVORED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.JobUnfavored, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_UNFAVORED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.LoggedIn, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_LOGGED_IN_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.NewsletterSettingChanged, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_NEWSLETTER_SETTING_CHANGED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.JobletterSettingChanged, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_JOBLETTER_SETTING_CHANGED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ProfileDataChanged, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_PROFILE_DATA_CHANGED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ProfileDocumentChanged, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_PROFILE_DOCUMENT_CHANGED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ProfileImageChanged, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_PROFILE_IMAGE_CHANGED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ProfileVideoChanged, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_PROFILE_VIDEO_CHANGED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ProfileIncompleteDialogOpened, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env
          .NUXT_ENV_API_WEBHOOK_PROFILE_INCOMPLETE_DIALOG_OPENED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ProfileSearchableChanged, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_PROFILE_SEARCHABLE_CHANGED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.Registered, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_REGISTERED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.OrganizationRegistered, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_ORGANIZATION_REGISTERED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.SedcardDownloaded, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_SEDCARD_DOWNLOADED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.UserSettingsChanged, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_USER_SETTINGS_CHANGED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.UserDeleted, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_USER_DELETED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.UserPasswordForgot, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_USER_PASSWORD_FORGOT,
      params,
    });
  });
  EventBus.$on(Events.OrganizationStared, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_ORGANIZATION_STARED,
      params,
    });
  });
  EventBus.$on(Events.OrganizationUnstared, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_ORGANIZATION_UNSTARED,
      params,
    });
  });
  EventBus.$on(Events.JobAlertSubscribed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_ALERT_SUBSCRIBED,
      params,
    });
  });
  EventBus.$on(Events.JobAlertUnsubscribed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_ALERT_UNSUBSCRIBED,
      params,
    });
  });
}
