export function prepareApiUrl(
  endpoint,
  {routeParameters = {}, getParameters = {}} = {}
) {
  return (
    process.env.NUXT_ENV_API_DOMAIN +
    prepareUrl(endpoint, {routeParameters, getParameters})
  );
}

export function prepareOpenStreetMapsSearchUrl(searchValue) {
  return (
    process.env.NUXT_ENV_OPEN_STREET_MAPS +
    '/search?format=json&city=' +
    searchValue
  );
}

export function prepareOpenStreetMapsReverseUrl(lat, lon) {
  return (
    process.env.NUXT_ENV_OPEN_STREET_MAPS +
    '/reverse?format=json&zoom=10&lat=' +
    lat +
    '&lon=' +
    lon
  );
}

export function prepareGeoApifySearchUrl(searchValue) {
  return (
    process.env.NUXT_ENV_GEOAPIFY +
    '?text=' +
    searchValue +
    '&type=city&apiKey=4248b66428aa4b42892868a4dea035b9&lang=de&filter=countrycode:de,at,ch'
  );
}

export function prepareGeoApifyReverseSearchUrl(lat, lon) {
  return (
    process.env.NUXT_ENV_GEOAPIFY_REVERSE +
    '?lat=' +
    lat +
    '&lon=' +
    lon +
    '&type=city&apiKey=4248b66428aa4b42892868a4dea035b9&lang=de&filter=countrycode:de,at,ch'
  );
}

export function prepareGeoApifySearchByIdUrl(searchId) {
  return (
    process.env.NUXT_ENV_GEOAPIFY_BY_PLACE_ID +
    '/place-details?id=' +
    searchId +
    '&type=city&apiKey=4248b66428aa4b42892868a4dea035b9&lang=de&filter=countrycode:de,at,ch'
  );
}

export function prepareOAuthRedirectUrlWithUtmCookie(utmCookie) {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  let input = {};
  if (utmCookie) {
    input = {
      date: today.toISOString(),
      utm_source: utmCookie.source ?? '',
      utm_medium: utmCookie.medium ?? '',
      utm_campaign: utmCookie.campaign ?? '',
      mtm_content: utmCookie.content ?? '',
      mtm_keyword: utmCookie.keyword ?? '',
      mtm_cid: utmCookie.cid ?? '',
      mtm_group: utmCookie.group ?? '',
    };
  }
  // Remove empty values
  Object.keys(input).forEach((k) => input[k] === '' && delete input[k]);
  return prepareUrl(process.env.NUXT_ENV_OAUTH_REDIRECT_URL, {
    getParameters: new URLSearchParams(input),
  });
}

export function prepareUrl(
  endpoint,
  {routeParameters = {}, getParameters = {}} = {}
) {
  let url = endpoint;

  for (const parameter in routeParameters) {
    url = url.replace('$' + parameter, routeParameters[parameter]);
  }

  if (getParameters instanceof URLSearchParams) {
    if (Array.from(getParameters.keys()).length > 0) {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += getParameters.toString();
    }
  } else {
    const preparedGetParameters = [];
    for (const param in getParameters) {
      preparedGetParameters.push(
        encodeURIComponent(param) +
          '=' +
          encodeURIComponent(getParameters[param])
      );
    }

    if (preparedGetParameters.length) {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += preparedGetParameters.join('&');
    }
  }

  return url;
}
