import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  async getCandidateAccount(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_ACCOUNT_ENDPOINT, {
        routeParameters: {id},
      })
    );
    if (response.status !== 200) {
      throw 'Error while loading CandidateAccount';
    }
    return response.data;
  },

  deleteCandidateAccount(ctx, {id, data}) {
    return getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_DELETE_ACCOUNT_ENDPOINT, {
        routeParameters: {id},
      }),
      data,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  patchCandidateAccount(ctx, {id, data}) {
    return getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_ACCOUNT_ENDPOINT, {
        routeParameters: {id},
      }),
      data,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  validateCandidateAccount() {
    return getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_VERIFY_USER_ACCOUNT),
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  patchResetPassword(ctx, {id, data}) {
    return getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_SET_NEW_PASSWORD, {
        routeParameters: {id},
      }),
      data,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  patchChangePassword(ctx, {id, data}) {
    return getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_CHANGE_PASSWORD, {
        routeParameters: {id},
      }),
      data,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  patchChangeEmail(ctx, {id, data}) {
    return getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_CHANGE_EMAIL, {
        routeParameters: {id},
      }),
      data,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  sendEmailForForgotPassword(ctx, email) {
    const response = getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_FORGOT_PASSWORD_EMAIL, {
        routeParameters: email,
      }),
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
    return response;
  },

  validateResetPasswordToken(ctx, token) {
    return getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_RESET_PASSWORD_VALIDATE_TOKEN, {
        routeParameters: token,
      }),
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  createByllAccount(ctx, id) {
    const response = getInstance().patch(
      prepareApiUrl(process.env.NUXT_ENV_API_BYLL_CREATE_ACCOUNT, {
        routeParameters: id,
      }),
      {},
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
    return response;
  },

  openByllAccount(ctx, id) {
    return getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_BYLL_OPEN_ACCOUNT, {
        routeParameters: id,
      }),
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },

  async getPbCoins(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_PBCOINS_DASHBOARD, {
        routeParameters: id,
      })
    );
    if (response.status !== 200) {
      throw 'Error while loading pbCoins';
    }
    return {
      total: response.data.total,
      currentMonth: response.data.current_month,
      lastMonth: response.data.last_month,
    };
  },

  async getNumLogins(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_NUMLOGINS_DASHBOARD, {
        routeParameters: id,
      })
    );
    if (response.status !== 200) {
      throw 'Error while loading getNumLogins';
    }
    return {
      data: response.data,
    };
  },

  async getLastLogin(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_LASTLOGIN_DASHBOARD, {
        routeParameters: id,
      })
    );
    if (response.status !== 200) {
      throw 'Error while loading getNumLogins';
    }
    return {
      data: response.data,
    };
  },

  async getStarRatings(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_STAR_RATINGS, {
        routeParameters: id,
      })
    );
    if (response.status !== 200) {
      throw 'Error while geting rating from user';
    }
    return {
      data: response.data['hydra:member'],
    };
  },

  // Auskommentiert weil aktuell nicht in Verwendung
  // setMauticId() {
  //   if (
  //     !this.$auth.loggedIn ||
  //     !this.$cookies.get('mtc_id') ||
  //     this.$auth.user.mauticId
  //   ) {
  //     return;
  //   }
  //
  //   const data = {};
  //   data.mauticId = this.$cookies.get('mtc_id');
  //
  //   return getInstance().patch(
  //     prepareApiUrl(process.env.NUXT_ENV_API_ACCOUNT_MAUTIC_ID_ENDPOINT, {
  //       routeParameters: {id: this.$auth.user.id},
  //     }),
  //     data,
  //     {
  //       headers: {
  //         'content-type': 'application/merge-patch+json',
  //       },
  //     }
  //   );
  // },

  verifyCandidateEmail(ctx, token) {
    return getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_VALIDATE_EMAIL, {
        routeParameters: token,
      }),
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    );
  },
};
