export const state = () => ({
  lastJobListingRoute: null,
  lastOrganizationListingRoute: null,
  lastArticleListingRoute: null,
  lastEventListingRoute: null,
});

export const mutations = {
  setJobListingRoute(state, route) {
    state.lastJobListingRoute = route;
  },
  setOrganizationListingRoute(state, route) {
    state.lastOrganizationListingRoute = route;
  },
  setArticleListingRoute(state, route) {
    state.lastArticleListingRoute = route;
  },
  setEventListingRoute(state, route) {
    state.lastEventListingRoute = route;
  },
};

export const getters = {
  hasJobListingRoute: (state) => state.lastJobListingRoute !== null,
  hasOrganizationListingRoute: (state) =>
    state.lastOrganizationListingRoute !== null,
  hasArticleListingRoute: (state) => state.lastArticleListingRoute !== null,
  hasEventListingRoute: (state) => state.lastEventListingRoute !== null,
};
