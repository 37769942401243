import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.ThankYouPageOpened, (params) => {
    $matomo.trackEvent(
      'ThankYouPage',
      Events.ThankYouPageOpened + params.type,
      '',
      1
    );
  });
  EventBus.$on(Events.ThankYouPageClosed, (params) => {
    $matomo.trackEvent(
      'ThankYouPage',
      Events.ThankYouPageClosed + params.type,
      '',
      1
    );
  });
  EventBus.$on(Events.ThankYouSwitchToProfileComplete, (params) => {
    $matomo.trackEvent(
      'ThankYouPage',
      Events.ThankYouSwitchToProfileComplete + params.type,
      '',
      1
    );
  });
}
