export function checkIfObjectIsEmpty(obj) {
  let isEmpty = true;

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      key !== 'hydra:view'
    ) {
      isEmpty = false;
      break;
    }
  }

  return isEmpty;
}

export function checkIfObjectHasMoreThen4Items(obj) {
  const objectKeys = Object.keys(obj);
  const objectCount =
    objectKeys.length - (objectKeys.includes('hydra:view') ? 1 : 0);
  return objectCount >= 4 ? true : false;
}

export function checkIfObjectContainsKeyWithValue(obj, key, value) {
  return Object.values(obj).some((objItem) => objItem[key] === value);
}
