import {jobListingRoutes} from '@/routes.config';
import {organizationListingRoutes} from '@/routes.config';
import {articleListingRoutes} from '@/routes.config';
import {eventListingRoutes} from '@/routes.config';

export default function (context) {
  // set breadcrumb route for job-listing
  if (
    context.route &&
    context.route.name &&
    jobListingRoutes.includes(
      context.route.name.substring(0, context.route.name.length - 5)
    )
  ) {
    context.store.commit(
      'breadcrumbs/setJobListingRoute',
      context.route.fullPath
    );
  }
  // set breadcrumb route for organization-listing
  if (
    context.route &&
    context.route.name &&
    organizationListingRoutes.includes(
      context.route.name.substring(0, context.route.name.length - 5)
    )
  ) {
    context.store.commit(
      'breadcrumbs/setOrganizationListingRoute',
      context.route.fullPath
    );
  }
  // set breadcrumb route for article-listing
  if (
    context.route &&
    context.route.name &&
    articleListingRoutes.includes(
      context.route.name.substring(0, context.route.name.length - 5)
    )
  ) {
    context.store.commit(
      'breadcrumbs/setArticleListingRoute',
      context.route.fullPath
    );
  }
  // set breadcrumb route for event-listing
  if (
    context.route &&
    context.route.name &&
    eventListingRoutes.includes(
      context.route.name.substring(0, context.route.name.length - 5)
    )
  ) {
    context.store.commit(
      'breadcrumbs/setEventListingRoute',
      context.route.fullPath
    );
  }
}
