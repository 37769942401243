let axiosInstance;

export const setInstance = function (instance) {
  axiosInstance = instance;
};

export const getInstance = function () {
  return axiosInstance;
};

export const getInstanceWithoutAuthorizationHeader = function () {
  const instance = axiosInstance.create();
  delete instance.defaults.headers.common['Authorization'];
  return instance;
};
