// Set Cookie Optionen
const cookieOptions = {
  secure: true,
  sameSite: 'lax',
  path: '/',
  expires: new Date(
    Date.now() + 60 * 60 * 24 * 1000 * process.env.NUXT_ENV_COOKIE_LIFETIME
  ),
};

export const state = () => ({
  displayConsent: false,
  cookiesAccepted: '',
  cookiesVersion: '',
  cookiesTimestamp: '',
});

export const mutations = {
  setDisplayConsent(state, value) {
    state.displayConsent = value;
  },
  setCookiesAccepted(state, value) {
    state.cookiesAccepted = value;
  },
  setCookiesVersion(state, value) {
    state.cookiesVersion = value;
  },
  setCookiesTimestamp(state, value) {
    state.cookiesTimestamp = value;
  },
};

export const actions = {
  checkCookieConsent({commit}) {
    const cookies_accepted = this.app.$cookies.get('GDPR:accepted');
    const cookies_version = this.app.$cookies.get('GDPR:version');
    const cookies_timestamp = this.app.$cookies.get('GDPR:timestamp');
    const time_period =
      process.env.NUXT_ENV_COOKIE_LIFETIME * 24 * 60 * 60 * 1000; // day hour  min  sec  msec
    const date = new Date();

    if (
      cookies_accepted &&
      parseInt(cookies_version, 10) ===
        parseInt(process.env.NUXT_ENV_COOKIE_VERSION, 10) &&
      cookies_timestamp &&
      new Date(parseInt(cookies_timestamp, 10)).getTime() + time_period >
        date.getTime()
    ) {
      commit('setCookiesAccepted', cookies_accepted);
      commit('setCookiesVersion', cookies_version);
      commit('setCookiesTimestamp', cookies_timestamp);
      commit('setDisplayConsent', false);
    } else {
      commit('setDisplayConsent', true);
    }
  },
  acceptAllCookies({commit}) {
    this.app.$cookies.set('GDPR:accepted', 'all', cookieOptions);
    this.app.$cookies.set(
      'GDPR:version',
      process.env.NUXT_ENV_COOKIE_VERSION,
      cookieOptions
    );
    this.app.$cookies.set('GDPR:timestamp', Date.now(), cookieOptions);
    commit('setDisplayConsent', false);
  },
  acceptRequiredCookies({commit}) {
    this.app.$cookies.set('GDPR:accepted', 'required', cookieOptions);
    this.app.$cookies.set(
      'GDPR:version',
      process.env.NUXT_ENV_COOKIE_VERSION,
      cookieOptions
    );
    this.app.$cookies.set('GDPR:timestamp', Date.now(), cookieOptions);
    commit('setDisplayConsent', false);
  },
  showCookieConsentBanner({commit}) {
    commit('setDisplayConsent', true);
  },
};

export const getters = {
  displayConsent(state) {
    return state.displayConsent;
  },
  cookieAccepted(state) {
    return state.cookieAccepted;
  },
  cookieVersion(state) {
    return state.cookieVersion;
  },
  cookieTimestamp(state) {
    return state.cookieTimestamp;
  },
};
