const middleware = {}

middleware['CheckOrganizationParameter'] = require('../middleware/CheckOrganizationParameter.js')
middleware['CheckOrganizationParameter'] = middleware['CheckOrganizationParameter'].default || middleware['CheckOrganizationParameter']

middleware['CheckUtmParameter'] = require('../middleware/CheckUtmParameter.js')
middleware['CheckUtmParameter'] = middleware['CheckUtmParameter'].default || middleware['CheckUtmParameter']

middleware['GetUserIp'] = require('../middleware/GetUserIp.js')
middleware['GetUserIp'] = middleware['GetUserIp'].default || middleware['GetUserIp']

middleware['PwaMatomoMeta'] = require('../middleware/PwaMatomoMeta.js')
middleware['PwaMatomoMeta'] = middleware['PwaMatomoMeta'].default || middleware['PwaMatomoMeta']

middleware['Sentry'] = require('../middleware/Sentry.js')
middleware['Sentry'] = middleware['Sentry'].default || middleware['Sentry']

middleware['StoreLastListing'] = require('../middleware/StoreLastListing.js')
middleware['StoreLastListing'] = middleware['StoreLastListing'].default || middleware['StoreLastListing']

middleware['TrackPage'] = require('../middleware/TrackPage.js')
middleware['TrackPage'] = middleware['TrackPage'].default || middleware['TrackPage']

export default middleware
