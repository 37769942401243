import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function (store) {
  EventBus.$on(Events.EventDetailsViewed, (params) => {
    const stateParams = store.state.tracking.paramsFromLastClickOnDetails ?? {};
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_EVENT_VIEWED_ENDPOINT,
      params: {...stateParams, ...params},
    });
  });
  EventBus.$on(Events.EventListingViewed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_EVENT_LISTING_VIEWED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.EventsShared, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_EVENTS_SHARED_ENDPOINT,
      params,
    });
  });
}
