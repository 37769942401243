// middleware/sentry.js

export default function (context) {
  if (context.app.$sentry && context.app.sentry && context.app.sentry.dsn) {
    if (context.isServer) {
      context.app.$sentry.setTag('rendering', 'ssr');
    } else {
      context.app.$sentry.setTag('rendering', 'csr');
    }
  }
}
