const cacheTtl = parseInt(process.env.NUXT_ENV_LOCALFORAGE_CACHE_TTL ?? 0);

function cacheKey(entity, id) {
  return 'strapi_' + entity + (id !== undefined ? '_' + id : '');
}

export default ({$strapi, $cache}, inject) => {
  inject('cachedStrapi', {
    findOne(entity, id, params) {
      return $cache.cache(
        cacheKey(entity, id),
        () => $strapi.findOne(entity, id, params),
        cacheTtl
      );
    },
    find(entity, params, cache_key = '') {
      if (cache_key === '') {
        cache_key = cacheKey(entity);
      } else {
        cache_key = 'strapi_' + cache_key;
      }

      return $cache.cache(
        cache_key,
        () => $strapi.find(entity, params),
        cacheTtl
      );
    },
  });
};
