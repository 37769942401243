/*eslint sort-keys: "error"*/
export default {
  AccountVerify: 'AccountVerify',
  ArticleDetailsViewed: 'ArticleDetailsViewed',
  ArticleListingViewed: 'ArticleListingViewed',
  ArticlesShared: 'ArticlesShared',
  ByllAccountCreated: 'ByllAccountCreated',
  ByllUsed: 'ByllUsed',
  EmailVerified: 'EmailVerified',
  EventDetailsViewed: 'EventDetailsViewed',
  EventListingViewed: 'EventListingViewed',
  EventsShared: 'EventsShared',
  GeneralContactRequest: 'GeneralContactRequest',
  JobAlertSubscribed: 'JobAlertSubscribed',
  JobAlertUnsubscribed: 'JobAlertUnsubscribed',
  JobApplicationClicked: 'JobApplicationClicked',
  JobApplicationClickedFromDetails: 'JobApplicationClickedFromDetails',
  JobApplicationClosed: 'JobApplicationClosed',
  JobApplicationFinished: 'JobApplicationFinished',
  JobApplicationOpened: 'JobApplicationOpened',
  JobAppliedTo: 'JobAppliedTo',
  JobBoxViewed: 'JobBoxViewed',
  JobDetailsClicked: 'JobDetailsClicked',
  JobDetailsViewed: 'JobDetailsViewed',
  JobFavored: 'JobFavored',
  JobListingViewed: 'JobListingViewed',
  JobUnfavored: 'JobUnfavored',
  JobletterSettingChanged: 'JobletterSettingChanged',
  JobsSearched: 'JobsSearched',
  JobsShared: 'JobsShared',
  LoggedIn: 'LoggedIn',
  MauticFormSent: 'MauticFormSent',
  NewsletterSettingChanged: 'NewsletterSettingChanged',
  OrganizationContacted: 'OrganizationContacted',
  OrganizationDetailsClicked: 'OrganizationDetailsClicked',
  OrganizationDetailsViewed: 'OrganizationDetailsViewed',
  OrganizationListingViewed: 'OrganizationListingViewed',
  OrganizationProjectRequest: 'OrganizationProjectRequest',
  OrganizationRegistered: 'OrganizationRegistered',
  OrganizationRegistrationFinished: 'OrganizationRegistrationFinished',
  OrganizationRegistrationOpened: 'OrganizationRegistrationOpened',
  OrganizationRegistrationStepClosed: 'OrganizationRegistrationStepClosed',
  OrganizationRegistrationStepFinished: 'OrganizationRegistrationStepFinished',
  OrganizationRegistrationStepStarted: 'OrganizationRegistrationStepStarted',
  OrganizationRegistrationSwitchToLogin:
    'OrganizationRegistrationSwitchToLogin',
  OrganizationStared: 'OrganizationStared',
  OrganizationUnstared: 'OrganizationUnstared',
  OrganizationsSearched: 'OrganizationsSearched',
  PageViewed: 'pageViewed',
  ProfileCompleteButNotVerifiedDialogOpened:
    'ProfileCompleteButNotVerifiedDialogOpened',
  ProfileDataChanged: 'ProfileDataChanged',
  ProfileDocumentChanged: 'ProfileDocumentChanged',
  ProfileImageChanged: 'ProfileImageChanged',
  ProfileIncompleteDialogOpened: 'ProfileIncompleteDialogOpened',
  ProfileSearchableChanged: 'ProfileSearchableChanged',
  ProfileVideoChanged: 'ProfileVideoChanged',
  Registered: 'Registered',
  //RegistrationAndApplication
  RegistrationAndApplicationClosed: 'RegistrationAndApplicationClosed',
  RegistrationAndApplicationFinished: 'RegistrationAndApplicationFinished',
  RegistrationAndApplicationForgetPassword:
    'RegistrationAndApplicationForgetPassword',
  RegistrationAndApplicationOpened: 'RegistrationAndApplicationOpened',
  RegistrationAndApplicationStepClosed: 'RegistrationAndApplicationStepClosed',
  RegistrationAndApplicationStepFinished:
    'RegistrationAndApplicationStepFinished',
  RegistrationAndApplicationStepStarted:
    'RegistrationAndApplicationStepStarted',
  RegistrationAndApplicationSwitchToApple:
    'RegistrationAndApplicationSwitchToApple',
  RegistrationAndApplicationSwitchToFacebook:
    'RegistrationAndApplicationSwitchToFacebook',
  RegistrationAndApplicationSwitchToGoogle:
    'RegistrationAndApplicationSwitchToGoogle',
  RegistrationAndApplicationSwitchToMicrosoft:
    'RegistrationAndApplicationSwitchToMicrosoft',
  //RegistrationBeforeApplication
  RegistrationBeforeApplicationClosed: 'RegistrationBeforeApplicationClosed',
  RegistrationBeforeApplicationFinished:
    'RegistrationBeforeApplicationFinished',
  RegistrationBeforeApplicationForgetPassword:
    'RegistrationBeforeApplicationForgetPassword',
  RegistrationBeforeApplicationOpened: 'RegistrationBeforeApplicationOpened',
  RegistrationBeforeApplicationStepBack:
    'RegistrationBeforeApplicationStepBack',
  RegistrationBeforeApplicationStepClosed:
    'RegistrationBeforeApplicationStepClosed',
  RegistrationBeforeApplicationStepFinished:
    'RegistrationBeforeApplicationStepFinished',
  RegistrationBeforeApplicationStepStarted:
    'RegistrationBeforeApplicationStepStarted',
  RegistrationBeforeApplicationSwitchToApple:
    'RegistrationBeforeApplicationSwitchToApple',
  RegistrationBeforeApplicationSwitchToFacebook:
    'RegistrationBeforeApplicationSwitchToFacebook',
  RegistrationBeforeApplicationSwitchToGoogle:
    'RegistrationBeforeApplicationSwitchToGoogle',
  RegistrationBeforeApplicationSwitchToMicrosoft:
    'RegistrationBeforeApplicationSwitchToMicrosoft',
  //RegistrationOrLogin
  RegistrationOrLoginFinished: 'RegistrationOrLoginFinished',
  RegistrationOrLoginForgetPassword: 'RegistrationOrLoginForgetPassword',
  RegistrationOrLoginOpened: 'RegistrationOrLoginOpened',
  RegistrationOrLoginStepClosed: 'RegistrationOrLoginStepClosed',
  RegistrationOrLoginStepFinished: 'RegistrationOrLoginStepFinished',
  RegistrationOrLoginStepStarted: 'RegistrationOrLoginStepStarted',
  RegistrationOrLoginSwitchToApple: 'RegistrationOrLoginSwitchToApple',
  RegistrationOrLoginSwitchToFacebook: 'RegistrationOrLoginSwitchToFacebook',
  RegistrationOrLoginSwitchToGoogle: 'RegistrationOrLoginSwitchToGoogle',
  RegistrationOrLoginSwitchToMicrosoft: 'RegistrationOrLoginSwitchToMicrosoft',
  SedcardDownloaded: 'SedcardDownloaded',
  //ThankYouPage
  ThankYouPageClosed: 'ThankYouPageClosed',
  ThankYouPageOpened: 'ThankYouPageOpened',
  ThankYouSwitchToProfileComplete: 'ThankYouSwitchToProfileComplete',
  //UpdateAndApplication
  UpdateAndApplicationFinished: 'UpdateAndApplicationFinished',
  UpdateAndApplicationOpened: 'UpdateAndApplicationOpened',
  UpdateAndApplicationStepBack: 'UpdateAndApplicationStepBack',
  UpdateAndApplicationStepClosed: 'UpdateAndApplicationStepClosed',
  UpdateAndApplicationStepFinished: 'UpdateAndApplicationStepFinished',
  UpdateAndApplicationStepStarted: 'UpdateAndApplicationStepStarted',
  UserDeleted: 'UserDeleted',
  UserPasswordForgot: 'UserPasswordForgot',
  UserSettingsChanged: 'UserSettingsChanged',
  WikisShared: 'WikisShared',
};
