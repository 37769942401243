import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  postFavorJobPosting(ctx, {id, data}) {
    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_FAVORITE_FAVOR_JOB_POSTING_ENDPOINT,
        {
          routeParameters: {id},
        }
      ),
      data
    );
  },

  postUnfavorJobPosting(ctx, id) {
    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_FAVORITE_UNFAVOR_JOB_POSTING_ENDPOINT,
        {routeParameters: {id}}
      ),
      {}
    );
  },

  automaticFavorJob(ctx, {id}) {
    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_JOB_POSTING_AUTOMATIC_FAVOR_ENDPOINT,
        {
          routeParameters: {id},
        }
      ),
      {}
    );
  },

  async getMyFavorites(ctx, getParameters = {}) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_FAVORITE_MY_FAVORITES_ENDPOINT, {
        getParameters,
      })
    );
    if (response.status !== 200) {
      throw 'Error while loading Favorites';
    }
    return {
      data: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    };
  },
};
