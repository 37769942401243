export default async function () {
  const workbox = await window.$workbox;
  if (workbox) {
    const installedListener = (event) => {
      if (event.isUpdate) {
        /*store.commit('notifications/showNotification', {
          message:
            'Wir haben eine Aktualisierung der Seite durchgeführt. Hierfür wird die Seite in wenigen Sekunden neu geladen.',
          color: 'success',
          timeout: -1,
          reloadButton: 'Direkt neu laden!',
        });*/
        window.location.reload();
        workbox.removeEventListener('installed', installedListener);
      }
    };
    workbox.addEventListener('installed', installedListener);
  }
}
