import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function (store) {
  EventBus.$on(Events.JobsSearched, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOBS_SEARCHED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.JobsShared, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOBS_SHARED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.JobBoxViewed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_BOX_VIEWED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.JobDetailsClicked, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_DETAILS_CLICKED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.JobDetailsClicked, (params) => {
    store.commit('tracking/setParamsFromLastClickOnDetails', params);
  });
  EventBus.$on(Events.JobApplicationClicked, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_JOB_APPLICATION_CLICKED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.JobApplicationClicked, (params) => {
    store.commit('tracking/setParamsFromLastClickOnApplication', params);
  });
  EventBus.$on(Events.JobApplicationClickedFromDetails, (params) => {
    const stateParams = store.state.tracking.paramsFromLastClickOnDetails ?? {};
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_JOB_APPLICATION_CLICKED_ENDPOINT,
      params: {...stateParams, ...params},
    });
  });
  EventBus.$on(Events.JobDetailsViewed, (params) => {
    const stateParams = store.state.tracking.paramsFromLastClickOnDetails ?? {};
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_DETAILS_VIEWED_ENDPOINT,
      params: {...stateParams, ...params},
    });
  });
  EventBus.$on(Events.JobListingViewed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_JOB_LISTING_VIEWED_ENDPOINT,
      params,
    });
  });
}
