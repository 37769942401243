import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.OrganizationRegistered, () => {
    $matomo.trackEvent(
      'OrganizationRegistration',
      Events.OrganizationRegistered,
      '',
      100
    );
  });
  EventBus.$on(Events.OrganizationRegistrationStepStarted, () => {
    $matomo.trackEvent(
      'OrganizationRegistration',
      Events.OrganizationRegistrationStepStarted,
      '',
      1
    );
  });
  EventBus.$on(Events.OrganizationRegistrationStepFinished, () => {
    $matomo.trackEvent(
      'OrganizationRegistration',
      Events.OrganizationRegistrationStepFinished,
      '',
      1
    );
  });
  EventBus.$on(Events.OrganizationRegistrationStepClosed, () => {
    $matomo.trackEvent(
      'OrganizationRegistration',
      Events.OrganizationRegistrationStepClosed,
      '',
      1
    );
  });
  EventBus.$on(Events.OrganizationRegistrationSwitchToLogin, () => {
    $matomo.trackEvent(
      'OrganizationRegistration',
      Events.OrganizationRegistrationSwitchToLogin,
      '',
      1
    );
  });
  EventBus.$on(Events.OrganizationRegistrationOpened, () => {
    $matomo.trackEvent(
      'OrganizationRegistration',
      Events.OrganizationRegistrationOpened,
      '',
      1
    );
  });
  EventBus.$on(Events.OrganizationRegistrationFinished, () => {
    $matomo.trackEvent(
      'OrganizationRegistration',
      Events.OrganizationRegistrationFinished,
      '',
      1
    );
  });
}
