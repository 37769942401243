import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.UpdateAndApplicationOpened, (params) => {
    $matomo.trackEvent(
      'UpdateAndApplication',
      Events.UpdateAndApplicationOpened,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.UpdateAndApplicationFinished, (params) => {
    $matomo.trackEvent(
      'UpdateAndApplication',
      Events.UpdateAndApplicationFinished,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.UpdateAndApplicationStepStarted, (params) => {
    $matomo.trackEvent(
      'UpdateAndApplication',
      Events.UpdateAndApplicationStepStarted + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.UpdateAndApplicationStepFinished, (params) => {
    $matomo.trackEvent(
      'UpdateAndApplication',
      Events.UpdateAndApplicationStepFinished + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.UpdateAndApplicationStepBack, (params) => {
    $matomo.trackEvent(
      'UpdateAndApplication',
      Events.UpdateAndApplicationStepBack + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.UpdateAndApplicationStepClosed, (params) => {
    $matomo.trackEvent(
      'UpdateAndApplication',
      Events.UpdateAndApplicationStepClosed + params.step,
      params.jobPostingId,
      1
    );
  });
}
