export default ({store}, inject) => {
  inject('notifications', {
    showNotification({
      message = '',
      color = '',
      timeout = -1,
      url = '',
      urlTitle = '',
      applicationUrl = '',
      width = '',
      reloadButton = '',
    }) {
      store.commit('notifications/showNotification', {
        message,
        color,
        timeout,
        url,
        urlTitle,
        applicationUrl,
        width,
        reloadButton,
      });
    },
  });
};
