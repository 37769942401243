import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  postApplyOnJobPosting(ctx, {id, data}) {
    return getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_APPLICATION_APPLY_ON_JOB_POSTING_ENDPOINT,
        {routeParameters: {id}}
      ),
      data
    );
  },

  async getMyApplications(ctx, getParameters = {}) {
    const response = await getInstance().get(
      prepareApiUrl(
        process.env.NUXT_ENV_API_APPLICATION_MY_APPLICATIONS_ENDPOINT,
        {getParameters}
      )
    );
    if (response.status !== 200) {
      throw 'Error while loading Applications';
    }
    return {
      data: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    };
  },
};
