
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'ApplicationLayout',

    components: {
      Notifications: () => import('@/components/Notifications'),
      CookieConsentBanner: () => import('@/components/CookieConsentBanner'),
    },
    mixins: [ScreenSize],
    data() {
      return {
        savedApplyJob: {},
      };
    },
    head() {
      const meta = [
        {
          hid: 'og:url',
          property: 'og:url',
          content: process.env.NUXT_ENV_BASE_URL + this.$route.fullPath,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'Website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Promotionjobs | Messejobs | Eventjobs | Promotionbasis',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content:
            'Promotionbasis ist Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content:
            'Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content:
            'Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: process.env.NUXT_ENV_BASE_URL + '/PB_Logo.svg',
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: process.env.NUXT_ENV_BASE_URL + '/PB_Logo.svg',
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex',
        },
        {
          hid: 'googlebot',
          name: 'googlebot',
          content: 'noindex',
        },
      ];

      return {
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        link: [
          {
            rel: 'canonical',
            hid: 'canonical',
            href: process.env.NUXT_ENV_BASE_URL + this.localePath({}),
          },
        ],
        meta: meta,
      };
    },
    errorCaptured(err, vm, info) {
      if (info === 'render') {
        this.$router.push('/error');
      }
    },
  };
