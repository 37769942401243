import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.JobAppliedTo, (params) => {
    $matomo.trackEvent(
      'Application',
      Events.JobAppliedTo,
      params.jobPostingId,
      50
    );
    $matomo.trackEvent(
      'Application',
      Events.JobAppliedTo + params.flow,
      params.jobPostingId,
      1
    );
  });
}
