import InMemoryCache from '@/caching/InMemoryCache';
import LocalForageCache from '@/caching/LocalForageCache';
import NoCache from '@/caching/NoCache';

const inMemoryCache = new InMemoryCache();

export default ({$localForage}, inject) => {
  if (process.client) {
    inject('cache', new LocalForageCache($localForage));
    inject('serverOnlyCache', new NoCache());
  } else if (process.server) {
    inject('cache', inMemoryCache);
    inject('serverOnlyCache', inMemoryCache);
  }
};
