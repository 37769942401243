import {getInstance} from '@/helper/Axios';

export default function imageToBase64(url) {
  return getInstance()
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then(
      (response) =>
        'data:' +
        response.headers['content-type'] +
        ';base64,' +
        Buffer.from(response.data, 'binary').toString('base64')
    );
}
