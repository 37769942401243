import Vue from 'vue';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {
  email,
  integer,
  is,
  min,
  min_value,
  numeric,
  required,
  size,
} from 'vee-validate/dist/rules';

extend('email', {
  ...email,
  message: 'Dieses Feld muss eine gültige E-Mail Adresse sein',
});

extend('emailEquals', {
  ...is,
  message: 'Email muss gleich sein',
});

extend('integer', {
  ...integer,
  message: 'Dieses Feld muss eine ganze Zahl sein',
});

extend('min', {
  ...min,
  message: 'Dieses Feld muss mindestens {length} lang sein',
});

extend('min_value', {
  ...min_value,
  message: 'Dieses Feld muss mindestens {min} sein',
});

extend('numeric', {
  ...numeric,
  message: 'Dieses Feld muss eine Zahl sein',
});

extend('size', {
  ...size,
  message: 'Die Datei ist zu groß',
});

extend('passwordEquals', {
  ...is,
  message: 'Passwort muss gleich sein',
});

extend('phone_number', {
  message: 'Dieses Feld muss eine gültige Telefonnummer sein',
  validate(value) {
    return /^[0-9/\-+\s]+$/.test(value);
  },
});

extend('zip_code_for_country', {
  params: ['country'], // Land als Parameter hinzufügen
  message:
    'Dieses Feld muss eine gültige Postleitzahl für das ausgewählte Land enthalten',
  validate(value, {country}) {
    const patterns = {
      at: /^\d{4}$/, // Österreich
      be: /^\d{4}$/, // Belgien
      bg: /^\d{4}$/, // Bulgarien
      ch: /^\d{4}$/, // Schweiz
      cy: /^\d{4}$/, // Zypern
      cz: /^\d{3}\s?\d{2}$/, // Tschechische Republik
      de: /^\d{5}$/, // Deutschland
      dk: /^\d{4}$/, // Dänemark
      ee: /^\d{5}$/, // Estland
      el: /^\d{5}$/, // Griechenland (EL)
      es: /^\d{5}$/, // Spanien
      fi: /^\d{5}$/, // Finnland
      fr: /^\d{5}$/, // Frankreich
      gb: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/, // Großbritannien
      hr: /^\d{5}$/, // Kroatien
      hu: /^\d{4}$/, // Ungarn
      ie: /^[A-Z]\d{2}\s?[A-Z0-9]{4}$/, // Irland
      it: /^\d{5}$/, // Italien
      lt: /^\d{5}$/, // Litauen
      lu: /^\d{4}$/, // Luxemburg
      lv: /^\d{4}$/, // Lettland
      mt: /^[A-Z]{3}\s?\d{2,4}$/, // Malta
      nl: /^\d{4}\s?[A-Z]{2}$/, // Niederlande
      pl: /^\d{2}-\d{3}$/, // Polen
      pt: /^\d{4}-\d{3}$/, // Portugal
      ro: /^\d{6}$/, // Rumänien
      se: /^\d{3}\s?\d{2}$/, // Schweden
      si: /^\d{4}$/, // Slowenien
      sk: /^\d{3}\s?\d{2}$/, // Slowakei
    };
    const regex = patterns[country.toLowerCase()];
    return regex ? regex.test(value) : true;
  },
});

extend('alnum_space', {
  message: 'Dieses Feld darf nur aus Zahlen und Buchstaben bestehen',
  validate(value) {
    return /^[a-z0-9\s]+$/i.test(value);
  },
});

extend('required', {
  ...required,
  message: 'Dieses Feld darf nicht leer sein',
});

extend('url', {
  message: 'Dieses Feld muss eine gültige URL sein',
  validate(value) {
    // @see https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
    return /^((http|https):\/\/)*[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(
      value
    );
  },
});

extend('ust_id_number_check', {
  params: ['country'], // Land als Parameter hinzufügen
  message: 'Dieses Feld muss eine gültige USt-IdNr sein',
  validate(value, {country}) {
    const patterns = {
      at: /^ATU[0-9]{8}$/,
      be: /^(BE)?(0?\d{9})$/,
      bg: /^(BG)?\d{9,10}$/,
      cy: /^(CY)?\d{8}[A-Z]$/,
      cz: /^(CZ)?\d{8,10}$/,
      dk: /^(DK)?\d{8}$/,
      ee: /^(EE)?\d{9}$/,
      el: /^(EL)?\d{9}$/,
      es: /^(ES)?([A-Z]\d{8}|[A-Z]\d{7}[A-Z])$/,
      fi: /^(FI)?\d{8}$/,
      fr: /^(FR)?\d{11}$/,
      hr: /^(HR)?\d{11}$/,
      hu: /^(HU)?\d{8}$/,
      ie: /^(IE)?\d{7}[A-Z]{1,2}$/,
      it: /^(IT)?\d{11}$/,
      lt: /^(LT)?\d{9}$/,
      lu: /^(LU)?((1[89]|2[0-9])[0-9]{9})$/,
      lv: /^(LV)?\d{11}$/,
      mt: /^(MT)?\d{8}$/,
      nl: /^(NL)?\d{9}B\d{2}$/,
      pl: /^(PL)?\d{10}$/,
      pt: /^(PT)?\d{9}$/,
      ro: /^(RO)?[1-9]\d{1,9}$/,
      se: /^(SE)?\d{12}$/,
      si: /^(SI)?\d{8}$/,
      sk: /^(SK)?[1-9]\d[2346-9]\d{7}$/,
    };
    const regex = patterns[country];
    return regex ? regex.test(value) : false;
  },
});

extend('additional_email_check', {
  message: 'Dieses Feld muss eine gültige E-Mail Adresse sein',
  validate(value) {
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || /[öäüß]/i.test(value)
      ? false
      : true;
  },
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
