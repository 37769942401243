import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function (store) {
  EventBus.$on(Events.OrganizationDetailsClicked, (params) => {
    store.commit('tracking/setParamsFromLastClickOnDetails', params);
  });

  EventBus.$on(Events.OrganizationDetailsViewed, (params) => {
    const stateParams = store.state.tracking.paramsFromLastClickOnDetails ?? {};
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_ORGANIZATION_VIEWED_ENDPOINT,
      params: {...stateParams, ...params},
    });
  });

  EventBus.$on(Events.OrganizationListingViewed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_ORGANIZATION_LISTING_VIEWED_ENDPOINT,
      params,
    });
  });

  EventBus.$on(Events.OrganizationsSearched, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_ORGANIZATIONS_SEARCHED_ENDPOINT,
      params,
    });
  });

  EventBus.$on(Events.OrganizationContacted, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_ORGANIZATION_CONTACTED,
      params,
    });
  });

  EventBus.$on(Events.OrganizationProjectRequest, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_ORGANIZATION_PROJECT_REQUEST,
      params,
    });
  });
  EventBus.$on(Events.GeneralContactRequest, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_GENERAL_CONTACT_REQUEST,
      params,
    });
  });
}
