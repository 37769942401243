
  export default {
    name: 'DefaultDrawerMobile',
    components: {
      MobileMenu: () => import('@/components/elements/MobileMenu'),
      SocialIcons: () => import('@/components/SocialIcons'),
      RegistrationOrLoginDialog: () =>
        import('@/components/candidate/RegistrationOrLoginDialog'),
    },
    props: {
      drawer: {
        type: Boolean,
        default: true,
      },
      menuItems: {
        type: Array,
        default: () => [],
      },
      mainLogo: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      title: 'Mobile Menu',
      switchToJobProviderTitle: 'Zu Jobanbieter wechseln',
      internalDrawer: true,
      iconColor: 'primaryYellow--text',
      jobProviderUrl: process.env.NUXT_ENV_LEGACY_URL,
      loginMenuModel: false,
      registerMenuModel: false,
    }),
    watch: {
      drawer() {
        this.internalDrawer = this.drawer;
      },
    },
    created() {
      this.internalDrawer = this.drawer;
    },
    methods: {
      toggleDrawer() {
        this.$emit('toggle-drawer');
      },
    },
  };
