class LocalForageCache {
  constructor(localForage) {
    this.localForage = localForage;
  }

  async has(key) {
    return Boolean(await this.get(key));
  }

  async get(key) {
    if (!this.localForage) {
      return null;
    }

    const data = this.localForage && (await this.localForage.getItem(key));

    if (!data) return data;

    const {expire, value} = data;

    if (expire < Date.now()) {
      this.localForage.removeItem(key);
      return null;
    }

    return value;
  }

  set(key, value, expire = false) {
    if (!this.localForage) {
      return Promise.resolve();
    }

    if (expire && typeof expire === 'number') {
      expire = Math.round(expire * 1000 + Date.now()); // * 1000 to use seconds
    }

    return this.localForage.setItem(key, {value, expire});
  }

  async cache(key, func, expire = false) {
    let value = await this.get(key);
    if (value) {
      return value;
    }

    value = await func();
    this.set(key, value, expire);
    return value;
  }
}

export default LocalForageCache;
