// This function scrolls to the element with the specified ID
const scrollToElement = (id) => {
  if (document.getElementById(id)) {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

// This function checks if the route has a hash and scrolls to the element
const scrollToHash = (to) => {
  if (to.hash) {
    // Wait for the next tick to ensure the DOM is updated
    // and the target element is available
    setTimeout(() => {
      scrollToElement(to.hash.replace('#', ''));
    }, 0);
  }
};

// This function attaches the scroll behavior customization to the router
const scrollBehavior = (to, from, savedPosition) => {
  scrollToHash(to, from);
  return savedPosition || {x: 0, y: 0};
};

export default ({app}) => {
  // Attach the scroll behavior customization to the router
  app.router.options.scrollBehavior = scrollBehavior;
};
