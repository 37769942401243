import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  async getOrganizationStarRatings(ctx, id) {
    const response = await getInstance().get(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_STAR_RATINGS, {
        routeParameters: {id},
      })
    );
    return response.data['hydra:totalItems'];
  },
  async giveStar(ctx, data) {
    const response = await getInstance().post(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_GIVE_STAR),
      data
    );
    return response.data;
  },
  async removeStar(ctx, id) {
    const response = await getInstance().delete(
      prepareApiUrl(process.env.NUXT_ENV_API_ORGANIZATION_REMOVE_STAR, {
        routeParameters: id,
      })
    );
    return {
      data: response.data,
    };
  },
};
