
  import LoginAndRegistrationDialog from '@/components/organization/LoginAndRegistrationDialog';
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'OrganizationLayout',
    components: {
      OrganizationAppBar: () => import('@/layouts/organization/AppBar'),
      OrganizationFooter: () => import('@/layouts/organization/Footer'),
      Notifications: () => import('@/components/Notifications'),
      CookieConsentBanner: () => import('@/components/CookieConsentBanner'),
      GeneralContactDialog: () => import('@/components/GeneralContactDialog'),
      LoginAndRegistrationDialog,
    },
    mixins: [ScreenSize],
    head() {
      const meta = [
        {
          hid: 'og:url',
          property: 'og:url',
          content: process.env.NUXT_ENV_BASE_URL + this.$route.fullPath,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'Website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Promotionjobs | Messejobs | Eventjobs | Promotionbasis',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content:
            'Promotionbasis ist Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content:
            'Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content:
            'Deutschlands große Plattform für Promotionjobs, Messejobs und Eventjobs. Ob Student, Schüler oder Rentner - bei uns findest du deinen nächsten Job.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: process.env.NUXT_ENV_BASE_URL + '/PB_Logo.svg',
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: process.env.NUXT_ENV_BASE_URL + '/PB_Logo.svg',
        },
      ];

      if (process.env.NUXT_ENV_BASE_URL !== 'https://www.promotionbasis.de') {
        meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex',
        });
        meta.push({
          hid: 'googlebot',
          name: 'googlebot',
          content: 'noindex',
        });
      }

      return {
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        script: [
          {
            src: '//ads1.promotionbasis.de/www/delivery/asyncjs.php',
            body: true,
            async: true,
            defer: true,
          },
        ],
        link: [
          {
            rel: 'canonical',
            hid: 'canonical',
            href: process.env.NUXT_ENV_BASE_URL + this.localePath({}),
          },
        ],
        meta: meta,
      };
    },
    computed: {
      registration() {
        return this.$route.query.registration;
      },
      generalContact() {
        return this.$route.query.generalContact;
      },
      topic() {
        return this.$route.query.topic;
      },
    },
    errorCaptured(err, vm, info) {
      if (info === 'render') {
        this.$router.push('/error');
      }
    },
  };
