import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.JobApplicationOpened, (params) => {
    $matomo.trackEvent(
      'JobApplication',
      Events.JobApplicationOpened,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.JobApplicationClosed, (params) => {
    $matomo.trackEvent(
      'JobApplication',
      Events.JobApplicationClosed,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.JobApplicationFinished, (params) => {
    $matomo.trackEvent(
      'JobApplication',
      Events.JobApplicationFinished,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.AccountVerify, (params) => {
    $matomo.trackEvent(
      'JobApplication',
      Events.AccountVerify + params.type,
      params.jobPostingId,
      1
    );
  });
}
