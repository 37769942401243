import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function (store) {
  EventBus.$on(Events.ArticleDetailsViewed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_ARTICLE_DETAILS_VIEWED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ArticleListingViewed, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint:
        process.env.NUXT_ENV_API_WEBHOOK_ARTICLE_LISTING_VIEWED_ENDPOINT,
      params,
    });
  });
  EventBus.$on(Events.ArticlesShared, (params) => {
    store.dispatch('api/tracking/callWebhook', {
      endpoint: process.env.NUXT_ENV_API_WEBHOOK_ARTICLES_SHARED_ENDPOINT,
      params,
    });
  });
}
