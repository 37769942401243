export default ({$cachedStrapi, $xhrCache}, inject) => {
  inject('xhrCachedStrapi', {
    findOne(entity, id, params) {
      return process.server
        ? $cachedStrapi.findOne(entity, id, params)
        : $xhrCache.getResourceById('strapi-' + entity);
    },
    find(entity, params) {
      return process.server
        ? $cachedStrapi.find(entity, params)
        : $xhrCache.getResourceById('strapi-' + entity);
    },
  });
};
