import EventBus from '@/events/EventBus';
import Events from '@/events/Events';

export default function ($matomo) {
  EventBus.$on(Events.RegistrationAndApplicationOpened, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationOpened,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationStepStarted, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationStepStarted + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationStepFinished, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationStepFinished + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationStepClosed, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationStepClosed + params.step,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationClosed, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationClosed,
      params.jobPostingId,
      5
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationFinished, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationFinished,
      params.jobPostingId,
      1
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationSwitchToGoogle, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationSwitchToGoogle + params.type,
      params.jobPostingId,
      5
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationSwitchToFacebook, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationSwitchToFacebook + params.type,
      params.jobPostingId,
      5
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationSwitchToApple, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationSwitchToApple + params.type,
      params.jobPostingId,
      5
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationSwitchToMicrosoft, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationSwitchToMicrosoft + params.type,
      params.jobPostingId,
      5
    );
  });
  EventBus.$on(Events.RegistrationAndApplicationForgetPassword, (params) => {
    $matomo.trackEvent(
      'RegistrationAndApplication',
      Events.RegistrationAndApplicationForgetPassword,
      params.type,
      1
    );
  });
}
