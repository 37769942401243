import cloneDeep from 'lodash/cloneDeep';

class InMemoryCache {
  constructor() {
    this.map = new Map();
    this.lastCleanUp = Date.now();
    this.cleanUpIntervall = 15 * 60 * 1000; // 15m
  }

  has(key) {
    this.cleanUp();
    this._deleteKeyIfIsExpired(key);

    return this.map.has(key);
  }

  get(key) {
    return this.has(key) ? cloneDeep(this.map.get(key).value) : null;
  }

  set(key, value, expire = false) {
    this.cleanUp();

    if (expire && typeof expire === 'number') {
      expire = Math.round(expire * 1000 + Date.now()); // * 1000 to use seconds
    }

    this.map.set(key, {value, expire});
  }

  async cache(key, func, expire = false) {
    let value = this.get(key);
    if (value) {
      return value;
    }

    value = await func();
    this.set(key, value, expire);
    return cloneDeep(value);
  }

  cleanUp() {
    if (this.lastCleanUp + this.cleanUpIntervall > Date.now()) {
      return;
    }
    for (const key of this.map.keys()) {
      this._deleteKeyIfIsExpired(key);
    }
    this.lastCleanUp = Date.now();
  }

  _deleteKeyIfIsExpired(key) {
    if (!this.map.has(key)) {
      return;
    }

    const {expire} = this.map.get(key);
    if (expire <= Date.now()) {
      this.map.delete(key);
    }
  }
}

export default InMemoryCache;
