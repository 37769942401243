
  import {fixedRouteSlugs} from '@/routes.config';

  export default {
    name: 'DefaultAppBar',

    components: {
      SearchPopoverMenu: () => import('@/components/SearchPopoverMenu'),
      LoginAndRegistrationDialog: () =>
        import('@/components/organization/LoginAndRegistrationDialog'),
      SocialIcons: () => import('@/components/SocialIcons'),
    },

    data: () => ({
      appBarNavigation: {
        id: 1,
        created_at: '2023-09-29T10:08:07.117Z',
        updated_at: '2023-10-10T06:58:44.647Z',
        items: [
          {
            id: 31,
            internal_title: 'Job inserieren',
            main: {
              id: 198,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Job inserieren',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: {
                id: 93,
                InternalTitle: 'Job inserieren',
                slug: 'job-inserieren',
                InternalDescription: null,
                Layout: null,
                Active: null,
                views: null,
                scope: 'promotionbasis_organization',
                show_seo_content: null,
              },
              article: null,
              activity: null,
              city: null,
              title: 'Job inserieren',
            },
            items: [],
          },
          {
            id: 32,
            internal_title: 'Recruiting-Lösungen',
            main: {
              id: 199,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Recruiting-Lösungen',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: 'Recruiting-Lösungen',
            },
            items: [
              {
                id: 202,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Stellenanzeigen-Pakete',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 94,
                  InternalTitle: 'Stellenanzeigen-Pakete',
                  slug: 'stellenanzeigen-pakete',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Stellenanzeigen-Pakete',
              },
              {
                id: 203,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Stellenanzeigen-Kontingente',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 95,
                  InternalTitle: 'Stellenanzeigen-Kontingente',
                  slug: 'stellenanzeigen-kontingente',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Stellenanzeigen-Kontingente',
              },
              {
                id: 204,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Sonderwerbeformen ',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 96,
                  InternalTitle: 'Sonderwerbeformen',
                  slug: 'sonderwerbeformen',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Sonderwerbeformen ',
              },
              {
                id: 205,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Bewerberdatenbank',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 97,
                  InternalTitle: 'Bewerberdatenbank',
                  slug: 'bewerberdatenbank',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_organization',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: 'Bewerberdatenbank',
              },
            ],
          },
          {
            id: 33,
            internal_title: 'Warum Promotionbasis?',
            main: {
              id: 200,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Warum Promotionbasis?',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: {
                id: 98,
                InternalTitle: 'Warum Promotionbasis?',
                slug: 'warum-promotionbasis',
                InternalDescription: null,
                Layout: null,
                Active: null,
                views: null,
                scope: 'promotionbasis_organization',
                show_seo_content: null,
              },
              article: null,
              activity: null,
              city: null,
              title: 'Warum Promotionbasis?',
            },
            items: [],
          },
          {
            id: 34,
            internal_title: 'Wissen/Ressourcen',
            main: {
              id: 201,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Wissen/Ressourcen',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: {
                id: 88,
                InternalTitle: 'Artikel-Übersicht Jobanbieter',
                slug: 'organization-articles',
                InternalDescription: null,
                Layout: null,
                Active: null,
                views: null,
                scope: 'promotionbasis_organization',
                show_seo_content: null,
              },
              article: null,
              activity: null,
              city: null,
              title: 'Wissen/Ressourcen',
            },
            items: [],
          },
        ],
      },
      overlay: false,
      menu: false,
      drawer: false,
      iconColor: 'primaryYellow--text',
      menuItems: [],
      menuItemsOutsideDropdown: [],
      menuItemsInDropdown: [],
      overSize: false,
      menuItemNumber: 4,
      registerMenuModel: false,
      debouncedResizeHandler: null,
    }),
    async fetch() {
      /* Json für Navigation zunächst statisch und nicht mehr dynamisch aus Strapi!
      // Query for app bar navigation single type
      const appBarNavigation = await this.$cachedStrapi
        .find('organization-app-bar-navigation')
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
       */

      if (
        this.appBarNavigation.items &&
        this.appBarNavigation.items.length > 0
      ) {
        this.appBarNavigation.items.forEach((menuItem) => {
          // prepare main menu item
          const mainMenuItem = this.prepareMenuItem(menuItem.main);
          mainMenuItem.items = [];

          // prepare menu sub items
          if (menuItem.items && menuItem.items.length > 0) {
            menuItem.items.forEach((subItem) => {
              const subMenuItem = this.prepareMenuItem(subItem);
              mainMenuItem.items.push(subMenuItem);
            });
          }
          this.menuItems.push(mainMenuItem);
          this.menuItemsOutsideDropdown = this.menuItems;
          this.menuItemNumber = this.menuItemsOutsideDropdown.length;
        });
      }
    },
    computed: {
      logoUrl() {
        return require(process.env.NUXT_ENV_FALLBACK_LOGO);
      },
    },
    watch: {
      drawer: function (val) {
        if (val) {
          document.documentElement.style.overflow = 'hidden';
        } else {
          document.documentElement.style.overflow = 'auto';
        }
      },
    },
    mounted() {
      document.documentElement.style.overflow = 'auto';
      this.calculateMainMenuSize();
      import('lodash/function').then(({debounce}) => {
        // Debounce-Funktion für das Resize-Event
        this.debouncedResizeHandler = debounce(() => {
          this.calculateMainMenuSize();
        }, 200); // 200 Millisekunden Debounce-Zeit

        window.addEventListener('resize', this.debouncedResizeHandler);
      });
    },
    beforeDestroy() {
      if (this.debouncedResizeHandler)
        window.removeEventListener('resize', this.debouncedResizeHandler);
    },
    methods: {
      checkClickOption(e, item) {
        if (item.items.length > 0) {
          e.stopPropagation();
        }
      },
      calculateMainMenuSize() {
        if (window.innerWidth >= 1360) {
          this.menuItemNumber = 4;
        } else if (window.innerWidth >= 1100 && window.innerWidth < 1360) {
          this.menuItemNumber = 3;
        } else if (window.innerWidth < 1100) {
          this.menuItemNumber = 2;
        }
        this.setupMenu();
      },
      setupMenu() {
        this.menuItemsOutsideDropdown = this.menuItems.slice(
          0,
          this.menuItemNumber
        );
        this.menuItemsInDropdown = this.menuItems.slice(this.menuItemNumber);
      },
      togglePopupMenu(event) {
        this.menu = event.menu;
        this.overlay = event.overlay;
      },
      prepareMenuItem(item) {
        const menuItem = {
          name: item.text,
        };

        // prepare route for menu item
        let route = null;
        if (item.route) {
          route = item.route;
        } else if (item.page && item.page.slug) {
          if (fixedRouteSlugs.includes(item.page.slug)) {
            route = {
              name: item.page.slug,
            };
          } else {
            route = {
              name: 'organization-slug',
              params: {slug: item.page.slug},
            };
          }
        } else if (item.article) {
          route = {
            name: 'organization-articles-category-article',
            params: {
              category: item.article.article_category.slug,
              article: item.article.slug,
            },
          };
        } else if (item.href) {
          menuItem.href = item.href;
          menuItem.target = item.target;
        }

        menuItem.route = route;

        menuItem.class = item.class;
        menuItem.icon = item.icon;
        menuItem.title = item.title;

        return menuItem;
      },
    },
  };
