export default {
  computed: {
    isExtraSmallScreen() {
      return this.$vuetify.breakpoint.width <= 400;
    },
    isXsScreen() {
      return (
        this.$vuetify.breakpoint.width <= this.$vuetify.breakpoint.thresholds.xs
      );
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width <= this.$vuetify.breakpoint.thresholds.sm
      );
    },
    isDesktopOrTablet() {
      return (
        this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.thresholds.sm
      );
    },
    isLgScreen() {
      return (
        this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.thresholds.md
      );
    },
  },
};
