export default ({$auth, $featureToggle}, inject) => {
  inject(
    'showJobAsPublished',
    // Eingeloggter Nutzer sieht den echten Published-Status
    // Nicht-Eingeloggter Nutzer sieht den eventuell gefakten Published-Status, abhängig vom Feature-Toggle
    function (is_published) {
      if (is_published || $auth.loggedIn) {
        return is_published;
      }

      return $featureToggle.toggles['show_jobs_as_published_for_visitors'];
    }
  );
};
