import Vue from 'vue';
import bootstrap from '@/events/bootstrap';
import EmitEvent from '@/mixins/EmitEvent';

Vue.mixin(EmitEvent);

let defined = false;
export default function ({store, $auth, $matomo}) {
  if (!defined) {
    defined = true;
    bootstrap(store, $auth, $matomo);
  }
}
