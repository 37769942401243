import {getInstance} from '@/helper/Axios';
import {prepareApiUrl} from '@/helper/UrlHelper';

export const actions = {
  async postProfileDocumentAttachment(ctx, {id, file}) {
    const formData = new FormData();
    formData.append('file', file);
    const response = await getInstance().post(
      prepareApiUrl(
        process.env.NUXT_ENV_API_PROFILE_DOCUMENTS_PROFILE_DOCUMENT_ATTACHMENTS,
        {
          routeParameters: {id},
        }
      ),
      formData
    );
    return response.data;
  },

  async deleteProfileDocumentAttachment(ctx, id) {
    const response = await getInstance().delete(
      prepareApiUrl(process.env.NUXT_ENV_API_PROFILE_DOCUMENT_ATTACHMENTS, {
        routeParameters: {id},
      })
    );
    return response.data;
  },
};
